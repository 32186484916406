// Animate elements

var animateHTML = function () {
  var elems,
    windowHeight
  var init = function () {
    elems = document.getElementsByClassName('hidden')
    windowHeight = window.innerHeight
    _addEventHandlers()
    _checkPosition()
  }
  var _addEventHandlers = function () {
    window.addEventListener('scroll', _checkPosition)
    window.addEventListener('resize', init)
  }
  var _checkPosition = function () {
    for (var i = 0; i < elems.length; i++) {
      var posFromTop = elems[i].getBoundingClientRect().top
      if (posFromTop - windowHeight <= 0) {
        elems[i].className = elems[i].className.replace('hidden', 'fade-in-element')
      }
    }
  }
  return {
    init: init
  }
}

animateHTML().init()

// Ligthbox

var galleryLink = document.getElementsByClassName('js-full');
var overlay = document.createElement('div');

for (var i = 0; galleryLink.length > i; i++) {
  galleryLink[i].addEventListener('click', openOverlay)
}

function openOverlay(e) {
  e.preventDefault();
  overlay.innerHTML = '';

  var imgURL = this.getAttribute('href');

  var body = document.querySelector('body');
  overlay.classList.add('lightbox');

  var image = document.createElement('img');
  image.src = imgURL;

  overlay.appendChild(image)
  body.appendChild(overlay);

}

function closeOverlay() {
  document.body.removeChild(overlay);
}

overlay.addEventListener('click', closeOverlay);
